export const getRoomsParam = rooms => {
  const roomsNumbers = [];
  Object.values(rooms).forEach((checked, index) => {
    const roomNumber = index + 1;

    if (checked === 'inne') {
      roomsNumbers.push('inne');
      return;
    }

    if (checked) {
      roomsNumbers.push(roomNumber.toString());
    }
  });

  return roomsNumbers.join();
};
