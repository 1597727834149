import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import useMedia from 'hooks/useMedia';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import About1 from 'components/Abouts/About1';
import About2 from 'components/Abouts/About2';

import About3 from 'components/Abouts/About3';
import About4 from 'components/Abouts/About4';
import About5 from 'components/Abouts/About5';
import Contact from 'components/Contact/Contact';
import Flats from 'components/Flats/Flats';
import LazyLoad from 'react-lazyload';

import Popup from 'components/shared/AdPopup';

import { ButtonPromotion } from 'components/ButtonPromotion';
import { getFlatsFromAPI, parseFlat } from 'utils/getFlatsFromApi';

const isSSR = typeof window === 'undefined';

const LazyPromotionDrawer = React.lazy(() =>
  import('components/PromotionDrawer')
);

const IndexPage = ({ data }) => {
  // LOCAL IMAGES
  const aboutImg = data.aboutImg.childImageSharp.fluid;
  const about1Img = data.about1Img.childImageSharp.fluid;
  const center1Img = data.center1Img.childImageSharp.fluid;
  const center2Img = data.center2Img.childImageSharp.fluid;
  const contactImg = data.contactImg.childImageSharp.fluid;
  const contactMapImg = data.contactMapImg.childImageSharp.fluid;
  const designImg = data.designImg.childImageSharp.fluid;
  const mobileHero = data.mobileHero.childImageSharp.fluid;

  const HOMEPAGE = data.wpgraphql.page.PageHome;

  const popupImg = HOMEPAGE.promotionPopup;
  const promoPdf = {
    pdfText: HOMEPAGE.promotionTextButton,
    pdfLink: HOMEPAGE.promotionRegulations?.link,
  };

  const heroImg = HOMEPAGE.bannerImg.imageFile.childImageSharp.fluid;
  const creditLogo = HOMEPAGE.creditBrand.imageFile.childImageSharp.fluid;
  const deerDesignLogo = HOMEPAGE.designBrand.imageFile.childImageSharp.fluid;

  const designOffers = HOMEPAGE.designPackages.map(el => ({
    name: el.designPackagesName,
    price: el.designPackagesPrice,
    pdf: el.designPackagesPdf?.url || null,
  }));

  const galleryImages = HOMEPAGE.galleryList.map(el => ({
    original: el.galleryListImg.imageFile.childImageSharp.fluid,
    type: el.galleryListCategory,
  }));

  const stats = HOMEPAGE.aboutStats.map(el => ({
    title: el.aboutStatsTitle,
    value: Number(el.aboutStatsNum),
  }));

  const persons = [...HOMEPAGE.creditTeam].map(el => ({
    name: el.creditTeamName,
    job: el.creditTeamRole,
    phone: el.creditTeamPhone,
    mail: el.creditTeamEmail,
  }));

  const creditOpeningHours = HOMEPAGE?.creditOpeningHours?.map(item => ({
    from: item?.creditOpeningHoursOpened,
    to: item?.creditOpeningHoursClosed,
    day: item?.creditOpeningHoursDay,
  }));

  const openingHoursGrouped =
    creditOpeningHours?.reduce((acc, curr) => {
      const { from, to, day } = curr;

      const existing = acc[from];

      if (existing) {
        existing.push({ from, to, day });
      } else {
        acc[from] = [{ from, to, day }];
      }

      return acc;
    }, {}) ?? [];

  const CREDIT_OPENING_HOURS =
    openingHoursGrouped &&
    Object.values(openingHoursGrouped).map(item => {
      let dayLabel = '';

      item.forEach((it, index) => {
        const isFirst = index === 0;
        const isLast = index === item.length - 1;

        if (isFirst) {
          dayLabel = it.day;
        }

        if (!isFirst) {
          dayLabel = `${dayLabel}, ${it.day}`;
        }

        if (isLast) {
          dayLabel = `${dayLabel}: godz. ${it.from} - ${it.to}`;
        }
      });

      return dayLabel;
    });

  const isMobile = useMedia('(max-width: 600px)');

  const [promoFlats, setPromoFlats] = React.useState([]);

  React.useEffect(() => {
    const getFlats = getFlatsFromAPI(
      'https://api.matexipolska.pl/export/api_getproducts/?key=585ee263d5148ce97aa833f48724be1f&mode=json&ExtraData=1&ShowAll=1&ID_ProductKind=1&ID_Investment=25',
      parseFlat
    );
    getFlats()
      .then(flats => {
        const filteredFlats = flats.filter(
          flat => flat.status !== 3 && flat.isPromotion
        );
        setPromoFlats(filteredFlats);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <SEO />
      {popupImg && (
        <Popup
          image={{
            src: popupImg.sourceUrl,
            alt: popupImg.altText,
          }}
        />
      )}
      {!isSSR && promoFlats.length > 0 && (
        <>
          <ButtonPromotion count={promoFlats.length} />
          <React.Suspense fallback={<></>}>
            <LazyPromotionDrawer promotionFlats={promoFlats} />
          </React.Suspense>
        </>
      )}
      <LazyLoad offset={50000} height="50vh" once>
        <Header
          image={isMobile ? mobileHero : heroImg}
          imageAlt="hero image"
          heading={HOMEPAGE.bannerTitle}
          description={HOMEPAGE.bannerSubtitle}
        />
      </LazyLoad>
      <main>
        <LazyLoad offset={50000} height="50vh" once>
          <About1
            heading={HOMEPAGE.aboutList[0].aboutListTitle}
            description={HOMEPAGE.aboutList[0].aboutListDesc}
            image={about1Img}
            imageAlt="about image"
            stats={stats}
          />
        </LazyLoad>
        <LazyLoad offset={50000} height="50vh" once>
          <About2
            heading={HOMEPAGE.aboutList[1].aboutListTitle}
            description={HOMEPAGE.aboutList[1].aboutListDesc}
            prospect={HOMEPAGE.aboutProspect}
            image={aboutImg}
            imageAlt="about image"
          />
        </LazyLoad>
        <LazyLoad offset={50000} height="70vh" once>
          <About3
            heading={HOMEPAGE.aboutList[2].aboutListTitle}
            description={HOMEPAGE.aboutList[2].aboutListDesc}
            image1={center1Img}
            image1Alt="about image"
            image2={center2Img}
            image2Alt="about image"
            mapImage={contactMapImg}
          />
        </LazyLoad>
        {/* <div style={{ position: 'relative' }}>
          <span id="mieszkania" style={{ position: 'absolute', top: '20px' }} />
          <LazyLoad offset={50000} height="50vh" once>
            <Flats />
          </LazyLoad>
        </div> */}
        <LazyLoad offset={50000} height="50vh" once>
          <About4
            heading={HOMEPAGE.designTitle}
            description={HOMEPAGE.designText}
            text1={HOMEPAGE.designPhoneText}
            text2={HOMEPAGE.designPhoneText2}
            phone={HOMEPAGE.designPhone}
            // buttonText={HOMEPAGE.designBtnText}
            // buttonPhone={HOMEPAGE.designPhone}
            offers={designOffers}
            image={designImg}
            imageAlt="deer design"
            logo={deerDesignLogo}
            logoAlt="deer design logo"
            galleryImages={galleryImages}
            finishingProps={{
              image: HOMEPAGE?.design2Img?.imageFile?.childImageSharp?.fluid,
              imageAlt: HOMEPAGE?.design2Img?.altText,
              logo: HOMEPAGE?.design2Brand?.imageFile?.childImageSharp?.fluid,
              logoAlt: HOMEPAGE?.design2Brand?.altText,
              heading: HOMEPAGE?.design2Title,
              description: HOMEPAGE?.design2Text,
              basicServicesList:
                HOMEPAGE?.design2BasicServices?.map(
                  item => item?.design2BasicServicesSingle
                ) ?? [],
              additionalServicesList:
                HOMEPAGE?.design2AdditionalServices?.map(
                  item => item?.design2AdditionalServicesSingle
                ) ?? [],
              team:
                HOMEPAGE?.design2Tutors?.map(item => ({
                  name: item?.design2TutorsName,
                  job: item?.design2TutorsPosition,
                  phone: item?.design2TutorsPhoneNumber,
                  email: item?.design2TutorsEmail,
                })) ?? [],
            }}
          />
        </LazyLoad>

        <LazyLoad offset={50000} height="50vh" once>
          <Contact
            image={contactImg}
            imageAlt="contact image"
            contactData={HOMEPAGE.contactAddress}
            description={HOMEPAGE.creditText}
            logo={creditLogo}
            logoAlt={HOMEPAGE.creditBrand.altText || ''}
            phone={HOMEPAGE.introPhoneText}
            email={HOMEPAGE.introEmailText}
            persons={persons}
            formAgree={HOMEPAGE.formAgree}
            formSubmit={HOMEPAGE.formSubmit}
            hours={HOMEPAGE.contactOfficeHours.map(
              ({ contactOfficeHoursHour }) => contactOfficeHoursHour
            )}
            infos={
              HOMEPAGE.contactInfo
                ? HOMEPAGE.contactInfo.map(
                    ({ contactInfoInfo }) => contactInfoInfo
                  )
                : ['']
            }
            promoPdf={promoPdf}
            creditProps={{
              text1: HOMEPAGE.creditText2,
              text2: HOMEPAGE.creditAddendum,
              openingHoursLabels: CREDIT_OPENING_HOURS,
            }}
          />
        </LazyLoad>
      </main>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query HomePage {
    wpgraphql {
      page(id: "/strona-glowna/", idType: URI) {
        PageHome {
          formAgree {
            formAgreeContent
            formAgreeRequired
            formAgreeName
            formAgreeYlclass
            formAgreeYlname
          }
          formSubmit
          bannerSubtitle
          bannerTitle
          bannerImg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          introDesc
          introEmailBtn
          introEmailText
          introHeading
          introPhoneBtn
          introPhoneText
          aboutList {
            aboutListTitle
            aboutListDesc
          }
          aboutStats {
            aboutStatsNum
            aboutStatsTitle
          }
          aboutProspect {
            aboutProspectTitle
            aboutProspectText
            aboutProspectAttachment {
              link
            }
          }
          creditText
          creditTeam {
            creditTeamEmail
            creditTeamName
            creditTeamPhone
            creditTeamRole
          }
          creditBrand {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 350, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          creditText2
          creditAddendum
          creditOpeningHours {
            creditOpeningHoursClosed
            creditOpeningHoursDay
            creditOpeningHoursOpened
          }
          galleryList {
            galleryListCategory
            galleryListImg {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          galleryHeading
          designPhone
          designText
          designTitle
          # designBtnText
          designBrand {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 350, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          designPhoneText2
          designPhoneText
          designPhone
          designPackages {
            designPackagesName
            designPackagesPrice
            designPackagesPdf {
              uri
            }
          }
          design2Title
          design2Text
          design2BasicServices {
            design2BasicServicesSingle
          }
          design2AdditionalServices {
            design2AdditionalServicesSingle
          }
          design2Tutors {
            design2TutorsEmail
            design2TutorsName
            design2TutorsPhoneNumber
            design2TutorsPosition
          }
          design2Brand {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 350, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          design2Img {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          contactInfo {
            contactInfoInfo
          }
          contactOfficeHours {
            contactOfficeHoursHour
          }
          contactAddress
          contactMapLat
          contactMapLng
          formHeading
          promotionPopup {
            altText
            sourceUrl
          }
          promotionRegulations {
            link
          }
          promotionTextButton
        }
      }
    }
    mobileHero: file(name: { eq: "hero_mobile" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    about1Img: file(name: { eq: "about1" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aboutImg: file(name: { eq: "about2" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    center1Img: file(name: { eq: "center1" }) {
      childImageSharp {
        fluid(maxWidth: 1125, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    center2Img: file(name: { eq: "center2" }) {
      childImageSharp {
        fluid(maxWidth: 1125, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    designImg: file(name: { eq: "design" }) {
      childImageSharp {
        fluid(maxWidth: 1125, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactImg: file(name: { eq: "contact" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactMapImg: file(name: { eq: "map2" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
